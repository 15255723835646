import React, { useState, useContext, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { AuthContext } from "../context/auth"
import firebase from "gatsby-plugin-firebase"
//import "firebase/firestore"
import VolverLogin from "../components/VolverLogin"
import {
  injectIntl,
  // FormattedMessage,
  // changeLocale,
  Link,
  navigate,
} from "gatsby-plugin-intl"
import axios from "axios"
import {
  Button,
  Select,
  InputLabel,
  MenuItem,
  //  Avatar,
  //  ListItemAvatar,
  //  ListItem,
  //  ListItemText,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Switch,
  // Box,
  Typography,
  makeStyles,
  Container,
} from "@material-ui/core"
import { graphql } from "gatsby"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textDecoration: "none",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    padding: 8,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    padding: 8,
  },
}))
const CargadorDeNotificaciones = ({ intl, data }) => {
  const { user } = useContext(AuthContext)
  const [dataNotificacion, setDataNotificacion] = useState({
    contador: 0,
    link: "",
    //    mostrar: false,
    // mostrar_link: false,
    texto: "",
    texto_link: "",
    //  tipo_link: "interno",
    titulo: "",
    error: null,
  })
  const [mostrarNotificacion, setMostrarNotificacion] = useState(false)
  const [mostrarLink, setMostrarLink] = useState(false)
  const tipoLinkArray = ["interno", "externo"]
  const [tipoLinkValor, setTipoLinkValor] = useState("")
  const handleChangeTipoLinkValor = e => {
    setTipoLinkValor(e.target.value)
  }

  const handleChange = e => {
    setDataNotificacion({
      ...dataNotificacion,
      [e.target.name]: e.target.value,
    })
  }
  const classes = useStyles()

  const handleSubmit = async e => {
    e.preventDefault()
    setDataNotificacion({ ...dataNotificacion, error: null })

    try {
      firebase
        .firestore()
        .collection("notificaciones")
        .doc("notificacion")
        .set({
          contador: dataNotificacion.contador + 1,
          link: dataNotificacion.link,
          mostrar: mostrarNotificacion,
          mostrar_link: mostrarLink,
          texto: dataNotificacion.texto,
          texto_link: dataNotificacion.texto_link,
          tipo_link: tipoLinkValor,
          titulo: dataNotificacion.titulo,
        })
      /*       .then(res => {
        props
          .updateProfile({
            displayName: `${dataRegistro.nombre} ${dataRegistro.apellido}`,
          })
          .then(function () {
            firebase.auth().signOut() //ACTIVAR PARA LA CONFIRMACION DEL EMAIL
            navigate("/") //ACTIVAR PARA LA CONFIRMACION DEL EMAIL
            // navigate("/inicio/") //TODO ver si sacar para que confirme el email
          })
          .catch(function (error) {
            // An error happened.
          })
      }) */
    } catch (err) {
      setDataNotificacion({ ...dataNotificacion, error: err.message })
    }
  }

  const notificacionActiva = true

  useEffect(() => {
    if (notificacionActiva) {
      let filas = {}

      firebase
        .firestore()
        .collection("notificaciones")
        .doc("notificacion")
        .get()
        .then(doc => {
          if (doc.exists) {
            //  console.log("Document data:", doc.data())
            filas = {
              // id: doc.id,
              contador: doc.data().contador,
              link: doc.data().link,
              texto: doc.data().texto,
              texto_link: doc.data().texto_link,
              titulo: doc.data().titulo,
            }
            setMostrarLink(doc.data().mostrar_link)
            setMostrarNotificacion(doc.data().mostrar)
            setTipoLinkValor(doc.data().tipo_link)
            setDataNotificacion(filas)
          } else {
            // doc.data() will be undefined in this case
            //  console.log("No such document!")
          }
        })
        .catch(error => {
          console.log("Error getting document:", error)
        })
    }
  }, [])

  return (
    <>
      {user != null &&
        (user.email === "gaston@fassilavalle.com.ar" ||
          user.email === "fiorella@virtualeventweb.com") ? (
        <Layout>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">
                Notificaciones
              </Typography>

              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <InputLabel id="mostrarnotificacion" style={{ padding: 0 }}>
                  Notificación activada
                </InputLabel>
                <Switch
                  checked={mostrarNotificacion}
                  onChange={e => setMostrarNotificacion(e.target.checked)}
                  name="notificacion_activada"
                  inputProps={{ "aria-label": "Notificación activada" }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="titulo"
                  label="Titulo"
                  type="text"
                  id="titulo"
                  value={dataNotificacion.titulo}
                  onChange={handleChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="texto"
                  label="Texto"
                  type="text"
                  id="texto"
                  value={dataNotificacion.texto}
                  onChange={handleChange}
                />

                <InputLabel id="mostrarlink" style={{ padding: 0 }}>
                  Link activado
                </InputLabel>
                <Switch
                  checked={mostrarLink}
                  onChange={e => setMostrarLink(e.target.checked)}
                  name="mostrar_link"
                  inputProps={{ "aria-label": "Link activado" }}
                />
                {mostrarLink ? (
                  <div>
                    <InputLabel id="tipolink" style={{ padding: 0 }}>
                      Destino del link
                    </InputLabel>
                    <Select
                      style={{ width: "100%", padding: 0 }}
                      id="mostrar"
                      value={tipoLinkValor}
                      onChange={handleChangeTipoLinkValor}
                    >
                      {tipoLinkArray.map(tipoLink => (
                        <MenuItem value={tipoLink} key={tipoLink}>
                          {tipoLink}
                        </MenuItem>
                      ))}
                    </Select>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="texto_link"
                      label="Texto del link"
                      type="text"
                      id="texto_link"
                      value={dataNotificacion.texto_link}
                      onChange={handleChange}
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="link"
                      label="Link"
                      type="text"
                      id="link"
                      value={dataNotificacion.link}
                      onChange={handleChange}
                    />
                  </div>
                ) : (
                  ""
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Guardar
                </Button>
              </form>
            </div>
          </Container>
        </Layout>
      ) : (
        <VolverLogin />
      )}
    </>
  )
}

export default injectIntl(CargadorDeNotificaciones)
